import { TextAreaViewProps, FormControlData, FormControlValue } from "../model";
import { createFormControl } from "solid-forms";

export function TextArea<V extends FormControlValue, D extends FormControlData>({
  control,
  ...props
}: TextAreaViewProps<V, D>) {
  const _control = Array.isArray(control) ? createFormControl<V, D>(...control) : control;
  return (
    <textarea
      {...props}
      classList={{
        invalid: !!_control.errors,
        touched: _control.isTouched,
        disabled: _control.isDisabled,
        // required: control.isRequired,
      }}
      value={_control.value}
      oninput={(e) => {
        _control.setValue(e.currentTarget.value as V);
      }}
      onblur={() => _control.markTouched(true)}
      onfocus={() => _control.markTouched(true)}
      onfocusin={() => _control.markTouched(true)}
      onfocusout={() => _control.markTouched(false)}
      disabled={_control.isDisabled}
      required={_control.isRequired}
    />
  );
}
